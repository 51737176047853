<!-- TODO: Update logic for this -->
<template>
  <div class="stds-checkbox-container">
    <s-checkbox
      v-bind="options"
      :modelValue="value"
      :isDisabled="disabled"
      :class="{
        'is-checked': state === CheckboxStateEnum.SELECT_ALL,
        'is-indeterminate': state === CheckboxStateEnum.INDETERMINATE
      }"
      @update:modelValue="handleModelUpdate"
    >
      <slot></slot>
    </s-checkbox>
    <st-error-message :name="fieldName" :showError="showFieldError" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, toRefs, watch } from 'vue';

import StErrorMessage from '@/components/validation/st-error-message.vue';
import useValidation from '@/composables/useValidation';
import { CheckboxStateEnum } from '@/enums/common.enum';
import type { CheckboxProps } from '@/types/common/form.type';

const props = defineProps<CheckboxProps>();

const { rules } = toRefs(props);

const emit = defineEmits<{
  'update:modelValue': [v: boolean];
}>();

const value = ref(props.modelValue);

const setFieldValue = ref<(value: boolean, shouldValidate?: boolean | undefined) => void>();

const fieldName = computed<string>(() => props.name ?? '');
const showFieldError = ref(false);

if (fieldName.value && !props.modelValue) {
  const { setValue, showError } = useValidation<boolean>({
    fieldName: fieldName.value,
    rules,
    value
  });

  setFieldValue.value = setValue;

  watch(
    () => showError.value,
    (v: boolean) => {
      showFieldError.value = v;
    }
  );
}

watch(
  () => props.modelValue,
  (v: boolean) => {
    value.value = v;
  }
);

const handleModelUpdate = (e: boolean) => {
  if (setFieldValue.value) {
    setFieldValue.value(e);
  }

  emit('update:modelValue', e);
};
</script>

<style lang="scss">
.stds-checkbox-container {
  .is-checked {
    .stds-checkbox::after {
      content: '\e95f' !important;
    }
  }

  .is-indeterminate {
    .stds-checkbox::after {
      content: '\e941' !important;
    }
  }
}
</style>
